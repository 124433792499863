import { Box, Flex } from 'components/box';
import { css } from '@emotion/react';
import { BannerInterface } from 'types/types';
import resourceUrl from '../../../../util/make-url';
import Head from 'next/head';

// NOTE: direct usage of the theme is discouraged in most circumstances.
// DO NOT emulate the following without understanding what you're doing.
import { breakpointsPx } from 'themes/onedayonly';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ChevronLeft as IconPrevious,
  ChevronRight as IconNext,
} from 'react-feather';
import Button from 'components/button';
import ThumbnailBullets from 'components/_shared/widgets/thumbnail-bullets';
import { Link } from 'components/configurable-routing';

const FADE_IN_TIME = 0.2;

interface BannerImages {
  mobileImage: string;
  tabletImage: string;
  desktopImage: string;
}

const NavigationButton = ({
  variant,
  onClick,
}: {
  variant: 'left' | 'right';
  onClick: () => void;
}) => (
  <Button
    position="absolute"
    variant="flat"
    top="calc(50% - 28px)"
    left={variant === 'left' ? '0' : undefined}
    right={variant === 'right' ? '0' : undefined}
    height="fit-content"
    width="fit-content"
    p="1px"
    zIndex={10}
    color="white"
    shouldFocus={false}
    hoverColor="white"
    fontColor="white"
    onClick={onClick}
  >
    {variant === 'right' ? <IconNext size="50" /> : <IconPrevious size="50" />}
  </Button>
);

const BannerPreloads = ({
  mobileImage,
  tabletImage,
  desktopImage,
}: BannerImages) => (
  <Head>
    <link
      rel="preload"
      href={mobileImage}
      as="image"
      media={`(max-width: ${breakpointsPx[0] - 1}px)`}
    />
    <link
      rel="preload"
      href={tabletImage}
      as="image"
      media={`(min-width: ${breakpointsPx[0]}px) and (max-width: ${
        breakpointsPx[1] - 1
      }px)`}
    />
    <link
      rel="preload"
      href={desktopImage}
      as="image"
      media={`(min-width: ${breakpointsPx[1]}px)`}
    />
  </Head>
);

const getBannerImageAssetUrls = (banner: BannerInterface): BannerImages => {
  const mobileImage = resourceUrl(banner.mobileImage.url, {
    isAssetUrl: banner.isAssetUrl,
    quality: 75,
    width: 500,
  });
  const tabletImage = resourceUrl(banner.tabletImage.url, {
    isAssetUrl: banner.isAssetUrl,
    quality: 75,
    width: 900,
  });
  const desktopImage = resourceUrl(banner.desktopImage.url, {
    isAssetUrl: banner.isAssetUrl,
    quality: 75,
    width: 1200,
  });
  return { mobileImage, tabletImage, desktopImage };
};

const BannerImage = ({
  mobileImage,
  tabletImage,
  desktopImage,
  altText,
}: BannerImages & { altText?: string }) => (
  <picture>
    <source
      srcSet={mobileImage}
      media={`(max-width: ${breakpointsPx[0] - 1}px)`}
    />
    <source
      srcSet={tabletImage}
      media={`(min-width: ${breakpointsPx[0]}px) and (max-width: ${
        breakpointsPx[1] - 1
      }px)`}
    />
    <img
      aria-label={altText}
      alt={altText}
      src={desktopImage}
      css={css`
        width: 100%;
        margin: 0;
        padding: 0;
      `}
    />
  </picture>
);

const BannerInner = ({
  bannerImages,
  altText,
  link,
}: {
  bannerImages: BannerImages;
  altText?: string;
  link?: string;
}) => {
  if (link) {
    return (
      <Link href={link} title={altText} target="_blank">
        <BannerImage altText={altText} {...bannerImages} />
      </Link>
    );
  }
  return <BannerImage altText={altText} {...bannerImages} />;
};

const getBanner = (banners: BannerInterface[], index: number) =>
  banners[index] || banners[0];

const BannerCarousel = ({ banners }: { banners: BannerInterface[] }) => {
  const [bannerIndex, setBannerIndex] = useState(0);
  const numBanners = banners.length;
  const [delay, setDelay] = useState(5000);

  const assetUrls = useMemo(() => {
    const urls: Record<string, BannerImages> = {};
    banners.forEach(banner => {
      urls[banner.id] = getBannerImageAssetUrls(banner);
    });
    return urls;
  }, [banners]);

  useEffect(() => {
    if (numBanners < 2) return;
    const timeoutId = setTimeout(() => {
      setBannerIndex(prevIndex => (prevIndex + 1) % numBanners);
      setDelay(5000);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay, numBanners]);

  const clickToBanner = useCallback((index: number) => {
    setBannerIndex(index);
    setDelay(10000);
  }, []);

  useEffect(() => {
    setBannerIndex(0);
  }, [banners]);

  const currentBanner = useMemo(() => getBanner(banners, bannerIndex), [
    banners,
    bannerIndex,
  ]);

  if (!currentBanner) return null;

  return (
    <Box key={banners.map(b => b.id).join('-')}>
      {banners.map(banner => (
        <BannerPreloads
          key={`banner-preloads-${banner.id}`}
          {...assetUrls[banner.id]}
        />
      ))}
      <Box
        tabIndex={0}
        m={0}
        p={0}
        position="relative"
        css={theme => css`
          border-radius: ${theme.radii[3]}px;
          overflow: hidden;
        `}
      >
        {numBanners > 1 && (
          <NavigationButton
            variant="left"
            onClick={() =>
              clickToBanner(
                bannerIndex === 0 ? numBanners - 1 : bannerIndex - 1
              )
            }
          />
        )}

        <BannerInner
          bannerImages={assetUrls[currentBanner.id]}
          altText={currentBanner.altText}
          link={currentBanner.link}
        />
        {numBanners > 1 && (
          <NavigationButton
            variant="right"
            onClick={() =>
              clickToBanner(
                bannerIndex === numBanners - 1 ? 0 : bannerIndex + 1
              )
            }
          />
        )}
      </Box>

      {numBanners > 1 && (
        <Flex
          width="100%"
          justifyContent="center"
          mt="-0.9rem"
          css={css`
            animation: fadeIn ${FADE_IN_TIME}s;
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          `}
        >
          <ThumbnailBullets
            size="small"
            showOnAllDevices
            total={numBanners}
            activeIndex={bannerIndex}
          />
        </Flex>
      )}
    </Box>
  );
};

const Banner = ({ banners }: { banners?: BannerInterface[] }) => {
  const bannersIsArray = Array.isArray(banners);
  if (bannersIsArray) {
    return <BannerCarousel banners={banners} />;
  }
  return null;
};

export default Banner;
