import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Flex } from 'components/box';
import preventDefaultAction from '../../../../util/prevent-default-action';
import { createFilledArray } from '../../../../util/arrays';

const PULSE_ANIMATION_MS = 600;

const ThumbnailBullet = ({
  isActive,
  isLeftEdge,
  isRightEdge,
  animateLeft,
  animateRight,
  size = 'normal',
}: {
  isActive: boolean;
  isLeftEdge: boolean;
  isRightEdge: boolean;
  animateLeft: boolean;
  animateRight: boolean;
  size?: 'small' | 'normal';
}) => (
  <button
    type="button"
    tabIndex={-1}
    onKeyPress={preventDefaultAction}
    onContextMenu={preventDefaultAction}
    css={theme => css`
      border: 3px solid white;
      background-color: ${theme.colors.lightishGrey};
      border-radius: 100%;
      outline: none;
      height: ${size === 'small' ? '11px' : '14px'};
      width: ${size === 'small' ? '11px' : '14px'};
      @media ${theme.mediaQueries.mobileOnly} {
        border: none;
        height: ${size === 'small' ? '6px' : '10px'};
        width: ${size === 'small' ? '6px' : '10px'};
        margin: 0 5px 0 5px;
      }
      transition: all 0.5s ease-out;

      ${isActive &&
      css`
        background-color: ${theme.colors.primary};
        transform: scale(1.25);
      `}

      ${(isLeftEdge || isRightEdge) &&
      css`
        transform: scale(0.5);
      `}

      ${isLeftEdge &&
      animateLeft &&
      css`
        animation: edgePulse ${PULSE_ANIMATION_MS}ms linear;
      `}

      ${isRightEdge &&
      animateRight &&
      css`
        animation: edgePulse ${PULSE_ANIMATION_MS}ms linear;
      `}
    `}
  />
);

const MAX_BULLETS = 7;

let timeoutIdLeft;
let timeoutIdRight;

interface Props {
  total: number;
  activeIndex: number;
  showOnAllDevices?: boolean;
  size?: 'small' | 'normal';
  mobileBulletHeight?: string;
}

const ThumbnailBullets = ({
  total,
  activeIndex,
  showOnAllDevices,
  size = 'normal',
  mobileBulletHeight = '40px',
}: Props) => {
  const bullets = Math.min(MAX_BULLETS, total);
  const half = Math.floor(bullets / 2);

  const [animateLeft, setAnimateLeft] = useState(false);
  const [animateRight, setAnimateRight] = useState(false);
  const [lastActiveIdx, setLastActiveIdx] = useState(activeIndex);
  const [activeBulletIdx, setActiveBulletIdx] = useState(activeIndex);

  useEffect(() => {
    if (activeIndex === lastActiveIdx) {
      return;
    }

    // set the active bullet index
    if (activeIndex === total - 1) {
      setActiveBulletIdx(bullets - 1);
    } else if (activeIndex > lastActiveIdx) {
      setActiveBulletIdx(idx => (idx + 1 < bullets - 1 ? idx + 1 : idx));
    } else if (activeIndex === 0) {
      setActiveBulletIdx(0);
    } else {
      setActiveBulletIdx(idx => (idx - 1 > 0 ? idx - 1 : idx));
    }

    if (activeIndex > lastActiveIdx) {
      if (!timeoutIdRight) {
        setAnimateRight(true);
        timeoutIdRight = setTimeout(() => {
          setAnimateRight(false);
          timeoutIdRight = undefined;
        }, PULSE_ANIMATION_MS);
      }
    } else if (!timeoutIdLeft) {
      setAnimateLeft(true);
      timeoutIdLeft = setTimeout(() => {
        setAnimateLeft(false);
        timeoutIdLeft = undefined;
      }, PULSE_ANIMATION_MS);
    }

    setLastActiveIdx(activeIndex);
  }, [lastActiveIdx, activeIndex, bullets, total]);

  const isLeftEdge = idx => {
    if (
      total > MAX_BULLETS &&
      idx === 0 &&
      (activeBulletIdx >= 1 || activeIndex > half)
    ) {
      return true;
    }
    return false;
  };

  const isRightEdge = idx => {
    if (
      total > MAX_BULLETS &&
      idx === bullets - 1 &&
      total - activeIndex > half - 1
    ) {
      return true;
    }
    return false;
  };

  return (
    <Flex
      className={!showOnAllDevices ? 'show-for-mobile-only' : undefined}
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      mt="1rem"
      css={theme => css`
        height: 18px;
        @keyframes edgePulse {
          0% {
            transform: scale(0.5);
          }
          50% {
            transform: scale(1);
          }
          100% {
            transform: scale(0.5);
          }
        }

        ${!showOnAllDevices &&
        css`
          @media ${theme.mediaQueries.mobileOnly} {
            position: absolute;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: ${mobileBulletHeight};
          }
        `}
      `}
    >
      {createFilledArray(bullets, null).map((_, idx) => (
        <ThumbnailBullet
          key={idx}
          animateLeft={animateLeft}
          animateRight={animateRight}
          isActive={idx === activeBulletIdx}
          isLeftEdge={isLeftEdge(idx)}
          isRightEdge={isRightEdge(idx)}
          size={size}
        />
      ))}
    </Flex>
  );
};

export default ThumbnailBullets;
